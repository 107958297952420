var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sideNav",staticClass:"side-nav-wrapper",attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleNav.apply(null, arguments)}}},[_c('transition',{attrs:{"name":"side-nav"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navShown),expression:"navShown"}],staticClass:"side-nav",style:({
        width: (_vm.width + "px"),
        color: _vm.$themeTokens.text,
        backgroundColor: _vm.$themeTokens.surface,
      })},[_c('FocusTrap',{on:{"shouldFocusFirstEl":function($event){return _vm.$emit('shouldFocusFirstEl')},"shouldFocusLastEl":_vm.focusLastEl}},[_c('div',{staticClass:"side-nav-scrollable-area",style:({ top: (_vm.headerHeight + "px"), width: (_vm.width + "px") })},[(_vm.themeConfig.sideNav.topLogo)?_c('img',{staticClass:"logo",style:(_vm.themeConfig.sideNav.topLogo.style),attrs:{"src":_vm.themeConfig.sideNav.topLogo.src,"alt":_vm.themeConfig.sideNav.topLogo.alt}}):_vm._e(),_c('CoreMenu',{ref:"coreMenu",style:({ backgroundColor: _vm.$themeTokens.surface }),attrs:{"role":"navigation","aria-label":_vm.$tr('navigationLabel')},scopedSlots:_vm._u([{key:"options",fn:function(){return [_vm._l((_vm.menuOptions),function(component){return _c(component,{key:component.name,tag:"component"})}),_c('SideNavDivider')]},proxy:true}])}),(_vm.showSoudNotice)?_c('div',{staticStyle:{"padding":"16px"}},[_c('LearnOnlyDeviceNotice')],1):_vm._e(),_c('div',{staticClass:"side-nav-scrollable-area-footer",style:({ color: _vm.$themeTokens.annotation })},[(_vm.themeConfig.sideNav.brandedFooter)?[(_vm.themeConfig.sideNav.brandedFooter.logo)?_c('img',{staticClass:"side-nav-scrollable-area-footer-logo",style:(_vm.themeConfig.sideNav.brandedFooter.logo.style),attrs:{"src":_vm.themeConfig.sideNav.brandedFooter.logo.src,"alt":_vm.themeConfig.sideNav.brandedFooter.logo.alt}}):_vm._e(),(_vm.themeConfig.sideNav.brandedFooter.paragraphArray
                  && _vm.themeConfig.sideNav.brandedFooter.paragraphArray.length)?_c('div',{staticClass:"side-nav-scrollable-area-footer-info"},_vm._l((_vm.themeConfig.sideNav.brandedFooter.paragraphArray),function(line,index){return _c('p',{key:index},[_vm._v("\n                  "+_vm._s(line)+"\n                ")])}),0):_vm._e()]:_vm._e(),(_vm.themeConfig.sideNav.showKolibriFooterLogo)?_c('CoreLogo',{staticClass:"side-nav-scrollable-area-footer-logo"}):_vm._e(),_c('div',{staticClass:"side-nav-scrollable-area-footer-info"},[_c('p',[_vm._v(_vm._s(_vm.footerMsg))]),_c('p',[_vm._v("© "+_vm._s(_vm.copyrightYear)+" Learning Equality")]),_c('p',[_c('KButton',{ref:"privacyLink",staticClass:"privacy-link",attrs:{"text":_vm.coreString('usageAndPrivacyLabel'),"appearance":"basic-link"},on:{"click":_vm.handleClickPrivacyLink}})],1)])],2)],1),_c('div',{staticClass:"side-nav-header",style:({
            height: _vm.headerHeight + 'px',
            width: (_vm.width + "px"), paddingTop: _vm.windowIsSmall ? '4px' : '8px',
            backgroundColor: _vm.$themeTokens.appBar,
          })},[_c('KIconButton',{ref:"closeButton",staticClass:"side-nav-header-icon",attrs:{"tabindex":"0","icon":"close","color":_vm.$themeTokens.textInverted,"ariaLabel":_vm.$tr('closeNav'),"size":"large"},on:{"click":_vm.toggleNav}}),_c('span',{staticClass:"side-nav-header-name",style:({ color: _vm.$themeTokens.textInverted })},[_vm._v(_vm._s(_vm.sideNavTitleText))])],1)])],1)]),_c('Backdrop',{directives:[{name:"show",rawName:"v-show",value:(_vm.navShown),expression:"navShown"}],staticClass:"side-nav-backdrop",attrs:{"transitions":true},on:{"click":_vm.toggleNav}}),(_vm.privacyModalVisible)?_c('PrivacyInfoModal',{on:{"cancel":function($event){_vm.privacyModalVisible = false},"submit":function($event){_vm.privacyModalVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }