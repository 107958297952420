var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiSelect',{staticClass:"k-select",class:{
    'k-select-inline': _vm.inline,
    'k-select-disabled': _vm.disabled,
  },attrs:{"value":_vm.selection,"options":_vm.options,"label":_vm.label,"floatingLabel":_vm.floatingLabel,"disabled":_vm.disabled,"invalid":_vm.invalid,"error":_vm.invalidText,"name":_vm.name,"clearable":_vm.clearable,"placeholder":_vm.placeholder},on:{"change":_vm.handleChange,"blur":function($event){return _vm.$emit('blur')}},scopedSlots:_vm._u([{key:"display",fn:function(){return [_vm._t("display")]},proxy:true},{key:"option",fn:function(ref){
  var highlighted = ref.highlighted;
  var index = ref.index;
  var option = ref.option;
  var selected = ref.selected;
return [_vm._t("option",null,{"highlighted":highlighted,"index":index,"option":option,"selected":selected})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }