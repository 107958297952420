<template>

  <CoreMenuDivider />

</template>


<script>

  import CoreMenuDivider from 'kolibri.coreVue.components.CoreMenuDivider';

  export default {
    name: 'SideNavDivider',
    components: {
      CoreMenuDivider,
    },
  };

</script>


<style lang="scss" scoped></style>
