import { render, staticRenderFns } from "./BirthYearSelect.vue?vue&type=template&id=5a6d88fd&scoped=true&"
import script from "./BirthYearSelect.vue?vue&type=script&lang=js&"
export * from "./BirthYearSelect.vue?vue&type=script&lang=js&"
import style0 from "./BirthYearSelect.vue?vue&type=style&index=0&id=5a6d88fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a6d88fd",
  null
  
)

export default component.exports