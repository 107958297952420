<template>

  <!-- populated by client-side router -->
  <router-view />

</template>


<script>

  export default {
    name: 'PageRoot',
  };

</script>


<style lang="scss"></style>
